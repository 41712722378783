import logo from './uscg-shenyang 5.svg'
import date from './July 28th 2023.svg';
import dateMobile from './July 28th 2023 (1).svg';
import footer from './Group 2796.png';
import mobileFooter from './Group 2797.png';
import './App.css';

function App() {

  const isMobile = window.innerWidth <= 991;

  return (
    <div className="App">

      <div className='top-panel'>
        <div className='header'>
          <img className='logo' src={logo} />
          <div>
            <div className='header-text'>
              Shenyang RELO English <br/>
              Teacher Training Seminar
            </div>
            {/*<div className='special'>The World is Your Classroom</div>*/}
          </div>
          <img className='date' src={date} />
        </div>
        <div className='mobile-header'>
          <img className='logo' src={logo} />
          <div>
            <div className='header-text'>
              Shenyang RELO English <br/>
              Teacher Training Seminar
            </div>
            {/*<div className='special'>The World is Your Classroom</div>*/}
            <img src={dateMobile} />
          </div>
        </div>
        {/*<iframe id="myframe" width="100%" height={isMobile? 600 : 1000} frameBorder="0" allowFullScreen*/}
        {/*        src="https://mudu.tv/live/watch/mn4kkanl"></iframe>*/}
        <video
          preload="none"
          width={isMobile? '70%' : '95%'}
          src={'https://file.udty.tech/1690598396652252011.mp4'}
          style={{outline: "none", objectFit: 'cover'}}
          controls
          disablePictureInPicture
          controlsList="nodownload"
        ></video>
      </div>


      <img className='footer' src={footer} />
      <img className='mobile-footer' src={mobileFooter} />
    </div>
  );
}

export default App;
